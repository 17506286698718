<template>
    <zw-sidebar @shown="shown" :title="$t('bank.title.assign')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">

                <b-card
                    border-variant="primary"
                    align="center"
                >
                    <b-card-text>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('bank.column.amount') }}:</strong></b-col>
                            <b-col class="text-left" cols="10">{{ payload.transfer.amount | priceFormat }}&euro;</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('bank.column.name') }}:</strong></b-col>
                            <b-col class="text-left" cols="10">{{ payload.transfer.name }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('bank.column.description') }}:</strong>
                            </b-col>
                            <b-col class="text-left" cols="10">{{ payload.transfer.description }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-right" cols="2"><strong>{{ $t('bank.column.date') }}:</strong></b-col>
                            <b-col class="text-left" cols="10">
                                {{ payload.transfer.date_time | formatDate('DD.MM.YYYY') }}
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
                <b-row>
                    <b-col cols="12">
                        <search-field v-model="search"
                                      name="search_offering_invoice"
                                      label-prefix="bank.label."
                                      :url="searchUrl"
                                      :callback="searchSelected"
                                      @input-object="searchSelected"
                        ></search-field>
                    </b-col>
                </b-row>

                <b-row align-h="end">
                    <b-col sm="12" class="text-sm-right text-xs-center">
                        <b-button :disabled="!form.offering_id" block @click="onSubmit" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            labelPrefix: 'bank.label.',
            searchUrl: window.apiUrl + '/invoices/search',
            payload: {},
            callback: null,
            form: {},
            search: null,
        }
    },
    methods: {
        shown() {
            this.loading = false
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.form.id = this.payload.id

                    this.$store.dispatch('BankTransfers/assign', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        searchSelected(data) {
            this.form.offering_id = data.offering_id;
            this.form.invoice_id = data.invoice_id;
        },
    },
    watch: {}
}
</script>